<template>
  <div class="">
    <div class="breadcrumb-m">
      <div class="left">
        <Breadcrumb>
          <BreadcrumbItem to="/">主控台</BreadcrumbItem>
          <BreadcrumbItem>站内信管理</BreadcrumbItem>
          <BreadcrumbItem>发送记录</BreadcrumbItem>
        </Breadcrumb>
      </div>
    </div>
    <div style="position: relative">
      <Spin v-if="workLoading" size="large" fix></Spin>
      <div class="ser-m">
        <Form ref="formInline" inline :label-width="70">
          <FormItem label="消息标题">
            <Input type="text" @on-keyup.13="ser" v-model="serData.title" placeholder="消息标题" maxlength="10"></Input>
          </FormItem>
          <FormItem label="发送时间">
            <DatePicker type="daterange" placeholder="发送时间" v-model="serData.send_at" style="width: 200px" @on-ok="ser" ></DatePicker>
          </FormItem>
          <FormItem label="更新时间">
            <DatePicker type="daterange" placeholder="更新时间" v-model="serData.update_at" style="width: 200px" @on-ok="ser" ></DatePicker>
          </FormItem>
          <FormItem :label-width="0">
            <Button type="primary" @click="ser" @keyup.13="ser">搜索</Button>
          </FormItem>
        </Form>
      </div>
      <Table size="small" :columns="columns" :data="data" :loading="loading">
        <template slot-scope="item" slot="id">
          {{item.row.id}}
          <div>
            <Tag v-if="item.row.is_send" color="geekblue">已发送</Tag>
            <Tag v-else color="lime">未发送</Tag>
          </div>
        </template>
        <template slot-scope="item" slot="action">
          <Button size="small" type="primary" @click="editLog(item.row.id)" v-if="!item.row.is_send">编辑</Button>
          <Divider v-if="!item.row.is_send" type="vertical" />
          <Button size="small" type="warning" @click="delLog(item.row.id)">删除</Button>
        </template>
      </Table>
      <div class="footer-m">
        <Page class-name="pagew page-r" :total="total" :current="page" show-total show-elevator show-sizer :page-size="page_size" :page-size-opts="[15,30,50]" @on-change="change_page" @on-page-size-change="change_page_size"/>
      </div>
      <Modal v-model="editMsg" scrollable class-name="vertical-center-modal" width="1030" title="编辑消息" footer-hide :closable="false" >
        <div style="position: relative;width: 100%;height: 100%;">
          <edit-mail ref="editMailRef" :modalM="true" @editSuccess="editSuccess" @cancelSuccess="cancelSuccess"></edit-mail>
          <Spin v-if="loadingModal" size="large" fix></Spin>
        </div>
      </Modal>
    </div>
  </div>
</template>
<script>
import editMail from '@/views/notice/editMail'
export default {
  name: "SendLog",
  components: {
    editMail
  },
  data() {
    return {
      columns:[
        {title: '消息ID',slot: 'id',minWidth:90},
        {title: '消息标题',key: 'title',minWidth:120},
        {title: '发送用户',key: 'customer_type',minWidth:100,
          render: (h, params) => {
            return  h('span',  {
              style:{color: params.row.customer_type == 2?'red':'green'}
            },
            params.row.customer_type == 2?'指定用户':'全部用户');
          }
        },
        {title: '发送方式',key: 'send_type',minWidth:100,
          render: (h, params) => {
            return  h('span',  {
                style:{color: params.row.send_type == 2?'red':'green'}
              },
              params.row.send_type == 1?'立即发送 ':'定时发送'
            );
          }
        },
        {title: '发送时间',key: 'send_at',minWidth:150},
        {title: '创建时间',key: 'create_at',minWidth:150},
        {title: '更新时间',key: 'update_at',minWidth:150},
        {title: '操作',slot:'action',width:120,align:'center'}
      ],
      data:[],
      serData:{title:''},
      page:1,
      page_size:0,
      total:0,
      loading:false,
      editMsg:false,
      loadingModal:true,
      workLoading:false
    }
  },
  created() {
    this.init();
  },
  methods:{
    init:function(){
      let _this = this;
      this.loading = true;
      this.requestApi('/adm/sendLog',{page_size:this.page_size,page:this.page,ser_data:this.serData}).then(function (res){
        _this.loading = false;
        if(res.status==1){
          _this.total = res.data.page.total;
          _this.current_page = res.data.page.current;
          _this.page_size = res.data.page.limit;
          _this.data = res.data.list;
        }
        _this.$nextTick(()=>{
          _this.checkIndex = -1;
        })
      })
    },
    ser:function(){
      this.page = 1;
      this.init();
    },
    editLog:function(sid){
      let _this = this;
      this.editMsg = true;
      _this.loadingModal = true;
      this.requestApi('/adm/getSendMail', {sid:sid}).then(function (res) {
        if(res.status == 1){
          _this.$refs.editMailRef.init(res.data,res.user_list);
        }else{
          _this.$refs.editMailRef.init(false,{});
        }
        _this.loadingModal = false;
      })
    },
    editSuccess:function(){
      this.editMsg = false;
      this.init();
    },
    cancelSuccess:function(){
      this.editMsg = false;
    },
    delLog:function(sid){
      let _this = this;
      this.$Modal.confirm({
        title: '提示',
        content: '确定要删除吗？',
        onOk: () => {
          this.workLoading = true;
          this.requestApi('/adm/delSendMail', {sid:sid}).then(function (res) {
            _this.workLoading = false;
            if(res.status == 1){
              _this.$Message.success('删除成功!');
              _this.page = 1;
              _this.init();
            } else
              _this.$Message.error('删除失败!');
          })
        }
      });
    },
    change_page:function (page){
      this.page = page;
      this.init();
    },
    change_page_size:function (pageSize){
      this.page_size = pageSize;
      this.page = 1;
      this.init();
    },
  }
}
</script>

<style lang="less">

</style>